<template>
    <v-dialog v-model="dialog" scrollable :width="dialogWidth" :overlay="false" transition="dialog-transition">
        <template v-slot:activator="{ on }">
            <div v-on="on">
                <slot></slot>
            </div>
        </template>
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">Usage statistics</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <div class="text-center py-5">
                            <v-btn text @click="gotoFiles(folderTypesLabels.WORKSPACE_FILES)">
                                Total usage
                                <v-icon small class="ml-1">mdi-open-in-new</v-icon>
                            </v-btn>
                        </div>
                        <div class="px-4">
                            <dashboard-loader v-if="fetchingSnapshotQuota"></dashboard-loader>
                            <template v-else>
                                <template v-if="!isEmpty(chartData) && !$isError(chartData)">
                                    <doughnut-chart :chartData="chartData" :options="chartOptions" />
                                </template>
                                <dashboard-error v-if="$isError(chartData)"></dashboard-error>
                                <dashboard-empty v-else-if="isEmpty(chartData)"></dashboard-empty>
                            </template>
                        </div>
                    </v-col>
                    <v-col cols="4">
                        <div class="text-center py-5">
                            <v-btn text @click="gotoFiles(folderTypesLabels.PERSONAL_FILES)">
                                Personal usage
                                <v-icon small class="ml-1">mdi-open-in-new</v-icon>
                            </v-btn>
                        </div>
                        <div class="px-4">
                            <dashboard-loader v-if="fetchingSnapshotQuota"></dashboard-loader>
                            <template v-else>
                                <template v-if="!isEmpty(personalChartData) && !$isError(personalChartData)">
                                    <doughnut-chart :chartData="personalChartData" :options="chartOptions" />
                                </template>
                                <dashboard-error v-if="$isError(personalChartData)"></dashboard-error>
                                <dashboard-empty v-else-if="isEmpty(personalChartData)"></dashboard-empty>
                            </template>
                        </div>
                    </v-col>
                    <v-col cols="4">
                        <div class="text-center py-5">
                            <v-btn text @click="gotoApps()">
                                Application usage
                                <v-icon small class="ml-1">mdi-open-in-new</v-icon>
                            </v-btn>
                        </div>
                        <div class="px-4">
                            <dashboard-loader v-if="fetchingSnapshotQuota"></dashboard-loader>
                            <template v-else>
                                <template v-if="!isEmpty(appChartData) && !$isError(appChartData)">
                                    <doughnut-chart :chartData="appChartData" :options="chartOptions" />
                                </template>
                                <dashboard-error v-if="$isError(appChartData)"></dashboard-error>
                                <dashboard-empty v-else-if="isEmpty(appChartData)"></dashboard-empty>
                            </template>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="dialog = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { enumsData } from '@/mixins/enums'
import { humanFileSize, interpolateColors } from '@/utils'
import { mapState } from 'vuex'
import { orderBy } from 'lodash'
import * as d3 from 'd3-scale-chromatic'

const DashboardLoader = () => import('@/components/DashboardLoader.vue')
const DashboardEmpty = () => import('@/components/DashboardEmpty.vue')
const DashboardError = () => import('@/components/DashboardError.vue')
const DoughnutChart = () => import('@/components/charts/DoughnutChart.vue')

const colorScale = d3.interpolateRainbow
const colorRangeInfo = {
    colorStart: 0,
    colorEnd: 1,
    useEndAsStart: false
}

export default {
    props: ['changeFileType'],
    mixins: [enumsData],
    components: { DoughnutChart, DashboardLoader, DashboardEmpty, DashboardError },
    data() {
        return {
            dialog: false,
            chartOptions: {
                legendTitle: 'Top usage:',
                valueFormatter: humanFileSize
            },
            chartData: {},
            personalChartData: {},
            appChartData: {}
        }
    },
    computed: {
        ...mapState('snapshotStore', ['snapshotQuota', 'fetchingSnapshotQuota']),
        dialogWidth() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return '100%'
                case 'sm':
                    return '100%'
                case 'md':
                    return '80%'
                case 'lg':
                    return '65%'
                case 'xl':
                    return '50%'
                default:
                    return '50%'
            }
        },
        freeDiscSpace() {
            return this.snapshotQuota.bytes_quota - (this.snapshotQuota.files_used + this.snapshotQuota.home_used + this.snapshotQuota.lib_used)
        }
    },
    watch: {
        snapshotQuota: {
            immediate: true,
            handler(quota) {
                if (this.$isError(quota)) {
                    this.chartData = quota
                    this.personalChartData = quota
                    this.appChartData = quota
                    return
                }

                // total usage
                let globalUsage = [
                    { label: 'Workspace files', data: quota.files_used },
                    { label: 'Personal files', data: quota.home_used },
                    { label: 'Application files', data: quota.lib_used }
                ]

                globalUsage = globalUsage.filter(usage => usage.data)
                globalUsage = this.orderedGlobalUsage(globalUsage)
                globalUsage.push({ label: 'Free space', data: this.freeDiscSpace })
                const globalUsageData = globalUsage.map(usage => usage.data && usage.data)
                const globalUsageLabel = globalUsage.map(usage => usage.label)
                this.chartData.labels = globalUsageLabel
                this.chartData.datasets = [{ data: globalUsageData, backgroundColor: interpolateColors(globalUsageData.length, colorScale, colorRangeInfo) }]

                // personal usage
                const userUsage = this.orderedUserUsage(quota.user_usage)
                const userUsageData = userUsage.map(user => user.usage)
                const userUsageLabel = userUsage.map(user => this.userName(user))
                this.personalChartData.labels = userUsageLabel
                this.personalChartData.datasets = [
                    { data: userUsageData, backgroundColor: interpolateColors(userUsageData.length, colorScale, colorRangeInfo) }
                ]

                // application usage
                const appUsage = this.orderedAppUsage(quota.app_usage)
                const appUsageData = appUsage.map(app => app.usage)
                const appUsageLabel = appUsage.map(app => app.long_id)
                this.appChartData.labels = appUsageLabel
                this.appChartData.datasets = [{ data: appUsageData, backgroundColor: interpolateColors(appUsageData.length, colorScale, colorRangeInfo) }]
            }
        }
    },
    methods: {
        orderedGlobalUsage(globalUsage) {
            return orderBy(globalUsage, ['data'], ['desc'])
        },
        orderedUserUsage(userUsage) {
            return orderBy(userUsage, ['usage'], ['desc']).slice(0, 3)
        },
        orderedAppUsage(appUsage) {
            return orderBy(
                appUsage,
                [
                    app => {
                        return app.usage || 0
                    }
                ],
                ['desc']
            ).slice(0, 3)
        },
        isEmpty(data) {
            if (this.$isError(data)) return true
            return data.datasets[0]?.data?.length === 0
        },
        gotoFiles(type) {
            this.dialog = false
            this.changeFileType(type)
        },
        gotoApps() {
            this.dialog = false
            this.$router.push({
                name: 'snapshot-applications',
                params: { oid: this.$route.params.oid, sid: this.$route.params.sid, iid: this.$route.params.iid, snid: this.$route.params.snid },
                query: this.$route.query
            })
        },
        userName(user) {
            return user.given_name === 'unknown' ? `Deleted user` : `${user.given_name} ${user.family_name}`
        },
        humanFileSize: function (bytes, si) {
            return humanFileSize(bytes, si)
        }
    }
}
</script>
